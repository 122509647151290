<template>
	<div id="portfolio" v-if="dataReady">

		<div class="page-header container-fluid text-center d-flex align-items-center justify-content-center bg-portfolio">
			<div class="page-header-content">
				<h1 class="text-line-1 mb10">
					<b>O NOSSO</b> PORTFOLIO
				</h1>
				<h2 class="text-line-2">
					Avalie por si mesmo a nossa qualidade, através dos trabalhos que já realizámos.
				</h2>
			</div>
		</div>

		<div class="container mb60">
			<div class="row text-center mb30">
				<h3 class="col-12">{{this.portfolio.title}}</h3>
			</div>
			
			<silent-box :gallery="gallery"></silent-box>
		</div>

		<MainFooter />

	</div>
</template>

<script>
import globals from '@/config/globals';
import axios from "axios";
import MainFooter from "@/components/footer.vue";

export default {
	name: "Portfolio",
	components: {
		MainFooter
	},
	data() {
		return {
			dataReady: false,
			pageBaseUrl: globals.BASE_URL,
			portfolioSlug: this.$route.params.slug,
			portfolio: null,
			gallery: []
		};
	},
	mounted() {
		axios
			.get(globals.COCKPIT_SERVER + "collections/get/portfolio" + globals.COCKPIT_TOKEN + "&filter[title_slug]=" + this.portfolioSlug + "&sort[_o]=1")
			.then(response => {
				this.portfolio = response.data.entries[0]
				this.dataReady = true
				this.changeMetaData()
				this.makeImagesArray(this.portfolio.images)
			});
	},
	methods: {
		changeMetaData() {
			this.$parent.metaTitle = 'Portfolio: ' + this.portfolio.title;
			this.$parent.metaDescription = 'Avalie por si mesmo a nossa qualidade, através dos trabalhos que já realizámos.';
		},
		makeImagesArray(anyObject) {
			Object.keys(anyObject).forEach( key => {
				this.gallery.push({
					src: globals.BASE_URL+'/'+anyObject[key].path,
					description: anyObject[key].meta.title
					/*
					src: anyObject[key].path,
					thumb: anyObject[key].path,
					title: anyObject[key].meta.title
					*/
				});
			});
		}
	}
};

</script>